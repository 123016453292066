export const baseUrl = "https://apimmc.feelingbetternow.com/api/v1"
// export const baseUrl = "http://localhost:5001/api/v1"

// let isTemp = localStorage.getItem('tempEnv')

export const loginUrl = (nonce = 'defaultNonce') => `https://mensanteid.b2clogin.com/mensanteid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_fbn_signup_signin&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=${nonce}&redirect_uri=${encodeURIComponent(
  baseUrl + "/callback"
)}&scope=openid&response_type=id_token&prompt=login&response_mode=form_post`
// export const baseUrl = "http://127.0.0.1:5000/api/v1";

export const disorderFiles = [
  "adhd-info.json",
  "AdjustmentDisorder-info.json",
  "Alcohol-info.json",
  "AngerProblems-info.json",
  "BipolarDisorder-info.json",
  "depression-info.json",
  "eatingDisorder-info.json",
  "gad-info.json",
  "insomnia-info.json",
  "ocd-info.json",
  "panicDisorder-info.json",
  "ptsd-info.json",
  "SocialAnxiety-info.json",
]

export const SupportedOrganizations = [
  `SABVC (Bow Valley College Student Asssociation)`,
  `British Columbia Institute of Technology (BCIT) Student Association`,
  `Camosun College Student Society (CCSS)`,
  `Cape Breton University (CBU)`,
  `Concordia Students' Association`,
  `Cumberland College`,
  `Georgian College GCSA`,
  `Great Plains College (GPC)`,
  `Kwantlen Student Association (KSA)`,
  `Lethbridge CSA`,
  `Students' Association of MacEwan University (SAMU)`,
  `Northern Alberta Institute of Technology Students' Association (NAITSA)`,
  `Norquest College Student Association`,
  `Okanagan College - Vernon Students' Association`,
  `Students' Association of Olds College (SAOC)`,
  `Parkland College`,
  `Ryerson Students' Union (RSU)`,
  `Red Deer College (RDC)`,
  `SPSA Saskatoon Campus`,
  `St. Francis Xavier University (STFX)`,
  `University of Guelph`,
  `University of Guelph Ridgetown Campus`,
  `Ontario Tech Students Union (OTSU)`,
  `The University of Regina Students' Union (URSU)`,
  `Booth University College`,
  `University of Western Ontario`,
  `Marsh & McLennan Companies (MMC)`,
  `Toronto Paramedic Services`,
  `Teachers Life`,
  `Dr. Jonathan Singerman`,
  `Halton Hills Family Health Team`,
  `Dr Suan Seh Foo`,
  `Dr Brian Shaw`,
  `SOBAPC (Dr. Sernaker)`,
  `Ozersky Clinic and MA (TGH)`,

  `Collège Boréal`,
  `Durham College`,
  `Fanshawe College`,
  `Fleming College`,
  `Seneca`,
  `New Brunswick Community College (NBCC)`,
  `Equitable Life of Canada`,
  `Meridian Credit Union`,
  `Ontario Teachers Insurance Program (OTIP)`,
]